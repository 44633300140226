var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("read", "cameras") || _vm.$can("read", "camerasmanagement")
    ? _c(
        "div",
        [
          _c(
            "b-media",
            { staticClass: "mb-2" },
            [
              _c(
                "h4",
                { staticClass: "mb-1" },
                [
                  _c("feather-icon", {
                    staticClass: "mr-50",
                    attrs: { icon: "CameraIcon", size: "22" }
                  }),
                  _vm._v(
                    " Camera Settings -- " +
                      _vm._s(_vm.cameraData.sitename) +
                      ", " +
                      _vm._s(_vm.cameraData.location_name)
                  ),
                  _c("br")
                ],
                1
              ),
              _c("small", [
                _vm._v("Camera: " + _vm._s(_vm.cameraData.unit_number))
              ]),
              !_vm.cameraData.is_active
                ? _c(
                    "b-badge",
                    {
                      staticClass: "text-capitalize ml-1",
                      attrs: { pill: "", variant: "light-danger" }
                    },
                    [_vm._v(" Disabled ")]
                  )
                : !_vm.cameraData.is_online
                ? _c(
                    "b-badge",
                    {
                      staticClass: "text-capitalize ml-1",
                      attrs: { pill: "", variant: "light-warning" }
                    },
                    [_vm._v(" Offline ")]
                  )
                : _c(
                    "b-badge",
                    {
                      staticClass: "text-capitalize ml-1",
                      attrs: { pill: "", variant: "light-success" }
                    },
                    [_vm._v(" Online ")]
                  ),
              _c("br")
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2 mb-1 border-bottom-primary" },
            [_c("b-col", [_c("h4", [_vm._v("Current Status")])])],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mb-1" },
            [
              _c("b-col", { attrs: { md: "6" } }, [
                _vm._v(
                  " Last Communication: " +
                    _vm._s(
                      _vm.formatDateTimeAMPM(
                        _vm.cameraData.last_communication.local
                          .replace("T", " ")
                          .replace("Z", "")
                      )
                    ) +
                    " (" +
                    _vm._s(_vm.cameraData.time_zone) +
                    ") "
                )
              ]),
              _c("b-col", { attrs: { md: "6" } }, [
                _vm._v(
                  " Camera Time Zone: " + _vm._s(_vm.cameraData.time_zone) + " "
                )
              ])
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mb-1" },
            [
              _c("b-col", { attrs: { md: "6" } }, [
                _vm._v(
                  " Firmware: v" +
                    _vm._s(_vm.cameraData.current_status.firmware.version) +
                    " -- "
                ),
                _c("small", [
                  _vm._v(
                    "Updated: " +
                      _vm._s(
                        _vm.formatDateTimeAMPM(
                          _vm.cameraData.current_status.firmware.updated_on.replace(
                            "T",
                            " "
                          )
                        )
                      )
                  )
                ])
              ]),
              _c("b-col", { attrs: { md: "6" } }, [
                _vm._v(
                  " Picture Interval: " +
                    _vm._s(_vm.cameraData.settings.capture_interval) +
                    " minutes "
                )
              ])
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c("b-col", { attrs: { md: "6" } }, [
                _vm._v(
                  " Motion Detection: " +
                    _vm._s(
                      _vm.cameraData.settings.is_motion_detection_enabled
                    ) +
                    " "
                )
              ])
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "border-top-secondary" },
            [
              _c("b-col", { staticClass: "mt-1" }, [
                _c("h4", [_vm._v("Battery Status")])
              ])
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "col-12 col-md-4 mb-2" },
                [
                  _c("b-card-header", [
                    _c(
                      "div",
                      [
                        _c("b-card-title", { staticClass: "mb-1" }, [
                          _vm._v(
                            " Latest Reading: " +
                              _vm._s(
                                Number(_vm.cameraData.current_status.battery)
                              ) +
                              "v "
                          )
                        ]),
                        _c("b-card-sub-title", [
                          _vm._v(
                            _vm._s(
                              _vm.formatDateTimeAMPM(
                                _vm.cameraData.last_communication.local
                                  .replace("T", " ")
                                  .replace("Z", "")
                              )
                            ) +
                              " (" +
                              _vm._s(_vm.cameraData.time_zone) +
                              ")"
                          )
                        ])
                      ],
                      1
                    )
                  ]),
                  _c("vue-apex-charts", {
                    attrs: {
                      type: "radialBar",
                      options: _vm.goalOverviewRadialBar.chartOptions,
                      series: _vm.goalOverviewRadialBar.series
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "col-12 col-md-8" },
                [
                  _c("battery-history-line-area-chart", {
                    attrs: { "heartbeat-data": _vm.heartbeatData }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "border-top-secondary" },
            [
              _c("b-col", { staticClass: "mt-1" }, [
                _c("h4", [_vm._v("Signal Strength")])
              ])
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mb-4" },
            [
              _c(
                "b-col",
                { staticClass: "col-12 col-md-4 mb-2" },
                [
                  _c("b-card-header", [
                    _c(
                      "div",
                      [
                        _c("b-card-title", { staticClass: "mb-1" }, [
                          _vm._v(
                            " Latest Reading: " +
                              _vm._s(
                                _vm.cameraData.current_status.antenna_signal
                              ) +
                              "dB (RSRQ) "
                          )
                        ]),
                        _c("b-card-sub-title", [
                          _vm._v(
                            _vm._s(
                              _vm.formatDateTimeAMPM(
                                _vm.cameraData.last_communication.local
                                  .replace("T", " ")
                                  .replace("Z", "")
                              )
                            ) +
                              " (" +
                              _vm._s(_vm.cameraData.time_zone) +
                              ")"
                          )
                        ])
                      ],
                      1
                    )
                  ]),
                  _c("vue-apex-charts", {
                    attrs: {
                      type: "bar",
                      options: _vm.salesBar.chartOptions,
                      series: _vm.salesBar.series
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "col-12 col-md-8" },
                [
                  _c("signal-history-line-area-chart", {
                    attrs: { "heartbeat-data": _vm.heartbeatData }
                  })
                ],
                1
              )
            ],
            1
          ),
          isNaN(_vm.cameraData.current_status.solar_current)
            ? _c("b-row")
            : _c(
                "b-row",
                { staticClass: "border-top-secondary" },
                [
                  _c("b-col", { staticClass: "mt-1" }, [
                    _c("h4", [_vm._v("Solar Current")])
                  ])
                ],
                1
              ),
          isNaN(_vm.cameraData.current_status.solar_current)
            ? _c("b-row")
            : _c(
                "b-row",
                { staticClass: "mb-4" },
                [
                  _c(
                    "b-col",
                    { staticClass: "col-12 col-md-4 mb-2" },
                    [
                      _c("b-card-header", [
                        _c(
                          "div",
                          [
                            _c("b-card-title", { staticClass: "mb-1" }, [
                              _vm._v(
                                " Latest Reading: " +
                                  _vm._s(
                                    Number(
                                      _vm.cameraData.current_status
                                        .solar_current
                                    )
                                  ) +
                                  " mA "
                              )
                            ]),
                            _c("b-card-sub-title", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatDateTimeAMPM(
                                    _vm.cameraData.last_communication.local
                                      .replace("T", " ")
                                      .replace("Z", "")
                                  )
                                ) +
                                  " (" +
                                  _vm._s(_vm.cameraData.time_zone) +
                                  ")"
                              )
                            ])
                          ],
                          1
                        )
                      ]),
                      _c("vue-apex-charts", {
                        attrs: {
                          type: "radialBar",
                          options: _vm.solarRadialBar.chartOptions,
                          series: _vm.solarRadialBar.series
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "col-12 col-md-8" },
                    [
                      _c("solar-history-line-area-chart", {
                        attrs: { "heartbeat-data": _vm.heartbeatData }
                      })
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }