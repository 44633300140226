var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { "no-body": "" } },
    [
      _c("b-card-header", [
        _c(
          "div",
          [
            _c("b-card-title", { staticClass: "mb-1" }, [
              _vm._v(" Solar History ")
            ]),
            _c("b-card-sub-title", [_vm._v("Past 30 Days")])
          ],
          1
        )
      ]),
      _c(
        "b-card-body",
        [
          _vm.lineAreaChartSpline.series.length
            ? _c("vue-apex-charts", {
                attrs: {
                  type: "area",
                  height: "400",
                  options: _vm.lineAreaChartSpline.chartOptions,
                  series: _vm.lineAreaChartSpline.series
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }