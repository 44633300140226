var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("read", "camerasmanagement")
    ? _c(
        "div",
        { staticClass: "mb-2" },
        [
          _c(
            "b-card",
            { staticClass: "mb-0", attrs: { "no-body": "" } },
            [
              _c(
                "div",
                { staticClass: "m-2" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                          attrs: { cols: "12", md: "6" }
                        },
                        [
                          _c("label", [_vm._v("Show")]),
                          _c("v-select", {
                            staticClass:
                              "per-page-selector d-inline-block mx-50",
                            attrs: {
                              dir: _vm.$store.state.appConfig.isRTL
                                ? "rtl"
                                : "ltr",
                              options: _vm.perPageOptions,
                              clearable: false
                            },
                            model: {
                              value: _vm.perPage,
                              callback: function($$v) {
                                _vm.perPage = $$v
                              },
                              expression: "perPage"
                            }
                          }),
                          _c("label", [_vm._v("entries")]),
                          _c(
                            "b-button",
                            {
                              staticClass: "ml-2",
                              attrs: {
                                variant: "primary",
                                disabled: this.$store.state.logs.fetchingList
                              },
                              on: { click: _vm.handleRefreshList }
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "RefreshCcwIcon" }
                              }),
                              _c("span", { staticClass: "text-nowrap ml-1" }, [
                                _vm._v("Refresh")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-end"
                          },
                          [
                            _c("b-form-input", {
                              staticClass: "d-inline-block mr-1",
                              attrs: { placeholder: "Search..." },
                              model: {
                                value: _vm.searchQuery,
                                callback: function($$v) {
                                  _vm.searchQuery = $$v
                                },
                                expression: "searchQuery"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("b-table", {
                ref: "refListTable",
                staticClass: "position-relative",
                attrs: {
                  items: _vm.fetchItems,
                  responsive: "",
                  fields: _vm.tableColumns,
                  "primary-key": "log_id",
                  "sort-by": _vm.sortBy,
                  "show-empty": "",
                  "empty-text": "",
                  "sort-desc": _vm.isSortDirDesc,
                  busy: _vm.isBusy
                },
                on: {
                  "update:sortBy": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sort-by": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sortDesc": function($event) {
                    _vm.isSortDirDesc = $event
                  },
                  "update:sort-desc": function($event) {
                    _vm.isSortDirDesc = $event
                  },
                  "update:busy": function($event) {
                    _vm.isBusy = $event
                  },
                  "row-clicked": _vm.handleListItemClick
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(type)",
                      fn: function(data) {
                        return [
                          data.item.input_name !== undefined
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "b-badge",
                                    {
                                      staticClass: "text-capitalize",
                                      attrs: {
                                        pill: "",
                                        variant:
                                          "light-" +
                                          _vm.resolveTypeVariant(
                                            data.item.input_name
                                          )
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(data.item.input_name) + " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : data.item.exception !== undefined
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "b-badge",
                                    {
                                      staticClass: "text-capitalize",
                                      attrs: {
                                        pill: "",
                                        variant:
                                          "light-" +
                                          _vm.resolveTypeVariant("Exception")
                                      }
                                    },
                                    [_vm._v(" Exception ")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "cell(battery)",
                      fn: function(data) {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-nowrap" },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "mr-1",
                                style: _vm.resolveBatteryColor(
                                  data.item.battery
                                ),
                                attrs: {
                                  icon: _vm.resolveBatteryIcon(
                                    data.item.battery
                                  ),
                                  size: "lg"
                                }
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "align-text-top text-capitalize"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.resolveBatteryVariant(
                                        data.item.battery
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(antenna_signal)",
                      fn: function(data) {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-nowrap" },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "mr-1",
                                style: _vm.resolveSignalColor(
                                  data.item.antenna_signal
                                ),
                                attrs: {
                                  icon: _vm.resolveSignalIcon(
                                    data.item.antenna_signal
                                  ),
                                  size: "lg"
                                }
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "align-text-top text-capitalize"
                                },
                                [
                                  _vm._v(
                                    _vm._s(data.item.antenna_signal) + " dBm"
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(exception)",
                      fn: function(data) {
                        return [
                          data.item.exception !== undefined
                            ? _c("div", [
                                _vm._v(" " + _vm._s(data.item.exception) + " ")
                              ])
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "cell(state)",
                      fn: function(data) {
                        return [
                          data.item.state !== undefined
                            ? _c(
                                "div",
                                { staticClass: "text-nowrap" },
                                [
                                  _c(
                                    "b-badge",
                                    {
                                      staticClass: "text-capitalize",
                                      attrs: {
                                        pill: "",
                                        variant:
                                          "light-" +
                                          _vm.resolveStateVariant(
                                            data.item.state
                                          )
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(data.item.state) + " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          data.item.state === undefined
                            ? _c(
                                "div",
                                { staticClass: "text-nowrap" },
                                [
                                  _c(
                                    "b-badge",
                                    {
                                      staticClass: "text-capitalize",
                                      attrs: {
                                        pill: "",
                                        variant:
                                          "light-" +
                                          _vm.resolveStateVariant("na")
                                      }
                                    },
                                    [_vm._v(" N/A ")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "cell(camera_date_time)",
                      fn: function(data) {
                        return [
                          _c("div", { staticClass: "text-nowrap" }, [
                            _c(
                              "span",
                              { staticClass: "align-text-top text-capitalize" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatDateTimeAMPM(
                                      data.item.time_camera.replace("T", " ")
                                    )
                                  )
                                )
                              ]
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2129416321
                )
              }),
              _c(
                "div",
                { staticClass: "mx-2 mb-2" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-center justify-content-sm-start",
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _c("span", { staticClass: "text-muted" }, [
                            _vm._v(
                              "Showing " +
                                _vm._s(_vm.dataMeta.from) +
                                " to " +
                                _vm._s(_vm.dataMeta.to) +
                                " of " +
                                _vm._s(_vm.dataMeta.of) +
                                " entries"
                            )
                          ])
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-center justify-content-sm-end",
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _c("b-pagination", {
                            staticClass: "mb-0 mt-1 mt-sm-0",
                            attrs: {
                              "total-rows": _vm.totalItems,
                              "per-page": _vm.perPage,
                              "first-number": "",
                              "last-number": "",
                              "prev-class": "prev-item",
                              "next-class": "next-item"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prev-text",
                                  fn: function() {
                                    return [
                                      _c("feather-icon", {
                                        attrs: {
                                          icon: "ChevronLeftIcon",
                                          size: "18"
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "next-text",
                                  fn: function() {
                                    return [
                                      _c("feather-icon", {
                                        attrs: {
                                          icon: "ChevronRightIcon",
                                          size: "18"
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              1308952388
                            ),
                            model: {
                              value: _vm.currentPage,
                              callback: function($$v) {
                                _vm.currentPage = $$v
                              },
                              expression: "currentPage"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-modal",
            {
              ref: "vmodal",
              attrs: {
                id: "vmodal",
                "ok-only": "",
                "ok-title": "Close",
                centered: "",
                size: "lg",
                title: _vm.modalViewerTitle
              },
              on: {
                close: function($event) {
                  _vm.showViewerModal = false
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.modalData) + " ")]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }