<template>
  <div v-if="$can('update', 'cameras') || $can('update', 'camerasmanagement')">

    <client-sites-list-add-new
      :is-add-new-client-sites-sidebar-active.sync="isAddNewClientSitesSidebarActive"
      :client-name-prop="cameraData.clientname"
      :client-display-name-prop="cameraData.client_display_name"
      @refetch-data="refetchData"
      @newSiteAdded="refreshClientSitesList"
    />

    <!-- Top Section -->
    <b-media class="mb-2">
      <template #aside>
        <!-- TODO: Show the latest Image here -->
      </template>
      <h4 class="mb-1">
        <feather-icon
          icon="CameraIcon"
          size="22"
          class="mr-50"
        />
        Camera Settings -- {{ cameraData.sitename }}, {{ cameraData.location_name }}<br>
      </h4>
      <small>Camera: {{ cameraData.unit_number }}</small>
      <b-badge
        v-if="!cameraData.is_active"
        pill
        variant="light-danger"
        class="text-capitalize ml-1"
      >
        Disabled
      </b-badge>
      <b-badge
        v-else-if="!cameraData.is_online"
        pill
        variant="light-warning"
        class="text-capitalize"
      >
        Offline
      </b-badge>
      <br>
    </b-media>

    <!-- Input Fields -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="observer"
    >
      <b-form
        @submit.prevent="handleSubmit(validateForm)"
      >
        <!-- General Settings -->
        <b-row
          v-if="userData.group === 'Management' || userData.group === 'EyeTrax_Admin'"
          class="mt-2 mb-1 border-bottom-primary"
        >
          <b-col>
            <h4>General Settings</h4>
          </b-col>
        </b-row>
        <b-row
          v-if="userData.group === 'Management' || userData.group === 'EyeTrax_Admin'"
        >
          <!-- Field: Camera ID -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Camera ID"
              label-for="camera_id"
            >
              <validation-provider
                #default="{ errors }"
                name="camera_id"
                rules="required"
              >
                <b-form-input
                  id="camera_id"
                  v-model="cameraData.camera_id"
                  :state="errors.length > 0 ? false:null"
                  :readonly="true"
                  name="camera_id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: Unit Number -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Unit Number"
              label-for="unit_number"
            >
              <validation-provider
                #default="{ errors }"
                name="unit_number"
                rules="required"
              >
                <b-form-input
                  id="unit_number"
                  v-model="cameraData.unit_number"
                  :state="errors.length > 0 ? false:null"
                  :readonly="true"
                  name="unit_number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: Year Built -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Year Built"
              label-for="year_built"
            >
              <validation-provider
                #default="{ errors }"
                name="year_built"
                rules="required"
              >
                <v-select
                  v-model="cameraData.year_built"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="yearBuiltList"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="year_built"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: Unit ID -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Unit ID"
              label-for="unit_id"
            >
              <validation-provider
                #default="{ errors }"
                name="unit_id"
              >
                <b-form-input
                  id="unit_id"
                  v-model="cameraData.unit_id"
                  :state="errors.length > 0 ? false:null"
                  name="unit_id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Controller BOM -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Controller BOM"
              label-for="controller_bom"
            >
              <validation-provider
                #default="{ errors }"
                name="controller_bom"
                rules="required"
              >
                <v-select
                  v-model="cameraData.controller_bom"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="controllerBOMOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="controller_bom"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- imei -->
          <b-col
            md="6"
          >
            <b-form-group
              label="IMEI"
              label-for="imei"
            >
              <validation-provider
                #default="{ errors }"
                name="imei"
                rules="required"
              >
                <b-form-input
                  id="imei"
                  v-model="cameraData.imei"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Camera Type -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Camera Type"
              label-for="camera_type"
            >
              <validation-provider
                #default="{ errors }"
                name="camera_type"
                rules="required"
              >
                <v-select
                  v-model="cameraData.camera_type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="cameraTypeOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="camera_type"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Device Path (*PI) -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Device Path (*PI)"
              label-for="device_path"
            >
              <validation-provider
                #default="{ errors }"
                name="device_path"
                rules="required"
              >
                <v-select
                  v-model="cameraData.device_path"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="devicePathOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="device_path"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Power Option -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Power Option"
              label-for="options_power"
            >
              <validation-provider
                #default="{ errors }"
                name="options_power"
                rules="required"
              >
                <v-select
                  v-model="cameraData.options.power"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="powerOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="options_power"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Has Pan / Tilt (PTZ) -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Has Pan / Tilt (PTZ)"
              label-for="has_pan_tilt"
            >
              <b-form-checkbox
                v-model="cameraData.options.has_pan_tilt"
                name="check-button"
                switch
                inline
              />
            </b-form-group>
          </b-col>
          <!-- Field: Phone -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Phone"
              label-for="phone"
            >
              <validation-provider
                #default="{ errors }"
                name="phone"
                rules="required"
              >
                <b-form-input
                  id="phone"
                  v-model="cameraData.phone"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: Cell Provider -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Cell Provider"
              label-for="cell_phone_carrier"
            >
              <validation-provider
                #default="{ errors }"
                name="cell_phone_carrier"
              >
                <v-select
                  v-model="cameraData.cell_phone_carrier"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="cellProviderOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="cell_phone_carrier"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Control Settings -->
        <b-row class="mt-2 mb-1 border-bottom-primary">
          <b-col>
            <h4>Control Settings</h4>
          </b-col>
        </b-row>
        <b-row
          v-if="(userData.group === 'Management' || userData.group === 'EyeTrax_Admin') && cameraData.is_online"
          class="mb-2"
        >
          <!-- Image Delay -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Image Delay"
              label-for="image_delay"
            >
              <validation-provider
                #default="{ errors }"
                name="image_delay"
                rules="required"
              >
                <v-select
                  v-model="cameraData.configuration.image_delay"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="imageDelayOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="image_delay"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Image Resolution -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Image Resolution"
              label-for="image_resolution"
            >
              <validation-provider
                #default="{ errors }"
                name="image_resolution"
                rules="required"
              >
                <v-select
                  v-model="cameraData.configuration.image_resolution"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="imageResolutionOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="image_resolution"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-if="(userData.group === 'Management' || userData.group === 'EyeTrax_Admin') && cameraData.is_online"
          class="mb-2"
        >
          <!-- Video Length -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Video Length"
              label-for="image_delay"
            >
              <validation-provider
                #default="{ errors }"
                name="video_length"
                rules="required"
              >
                <v-select
                  v-model="cameraData.configuration.video_length"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="videoLengthOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="video_length"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Video Resolution -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Video Resolution"
              label-for="video_resolution"
            >
              <validation-provider
                #default="{ errors }"
                name="video_resolution"
                rules="required"
              >
                <v-select
                  v-model="cameraData.configuration.video_resolution"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="videoResolutionOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="video_resolution"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-if="(userData.group === 'Management' || userData.group === 'EyeTrax_Admin') && cameraData.is_online"
          class="mb-2"
        >
          <!-- Motion Detection Enabled -->
          <b-col md="6">
            <b-form-group
              label="Motion Detection"
              label-for="is_motion_detection_enabled"
            >
              <b-form-checkbox
                v-model="cameraData.settings.is_motion_detection_enabled"
                name="check-button"
                switch
                inline
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-if="(cameraData.options.has_defender || (userData.group === 'Management' || userData.group === 'EyeTrax_Admin')) && cameraData.is_online"
          class="mb-2"
        >
          <!-- Defender -->
          <b-col md="2">
            <b-form-group
              label="Has Defender"
              label-for="has_defender"
            >
              <b-form-checkbox
                v-model="cameraData.options.has_defender"
                name="check-button"
                switch
                inline
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="cameraData.options.has_defender"
            md="2"
          >
            <b-form-group
              label="Defender Active"
              label-for="is_defender_enabled"
            >
              <b-form-checkbox
                v-model="cameraData.settings.is_relay3"
                name="check-button"
                switch
                inline
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <!-- Content Retention -->
          <b-col
            v-if="userData.group === 'Management' || userData.group === 'EyeTrax_Admin'"
            md="6"
          >
            <b-form-group
              label="Content Retention"
              label-for="expiry_days"
            >
              <validation-provider
                #default="{ errors }"
                name="expiry_days"
                rules="required"
              >
                <v-select
                  v-model="cameraData.expiry_days"
                  :options="expiryDaysOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="expiry_days"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            This setting change only effects any new content going forward. Previously saved content is not effected by this change.
          </b-col>
          <!-- Time Zone -->
          <b-col
            v-if="(cameraData.is_active || userData.group === 'Management' || userData.group === 'EyeTrax_Admin') && cameraData.is_online"
            md="6"
          >
            <b-form-group
              label="Time Zone"
              label-for="time_zone"
            >
              <validation-provider
                #default="{ errors }"
                name="time_zone"
                rules="required"
              >
                <v-select
                  v-model="cameraData.time_zone"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="timeZoneOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="time_zone"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-if="(userData.group === 'Management' || userData.group === 'EyeTrax_Admin') && cameraData.is_online"
          class="mt-2"
        >
          <!-- Heartbeat -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Heartbeat"
              label-for="heartbeat_interval"
            >
              <validation-provider
                #default="{ errors }"
                name="heartbeat_interval"
                rules="required"
              >
                <v-select
                  v-model="cameraData.settings.heartbeat_interval"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="heartbeatIntervalOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  :readonly="true"
                  input-id="heartbeat_interval"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Client Information and Notes -->
        <b-row
          v-if="userData.group === 'Management' || userData.group === 'EyeTrax_Admin'"
          class="mt-2 mb-1 border-bottom-primary"
        >
          <b-col>
            <h4>Client Information and Notes</h4>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <!-- Field: Client Name -->
          <b-col
            v-if="userData.group === 'Management' || userData.group === 'EyeTrax_Admin'"
            md="6"
          >
            <b-form-group
              label="Client"
              label-for="clientname"
            >
              <validation-provider
                #default="{ errors }"
                name="clientname"
                rules="required"
              >
                <v-select
                  v-model="cameraData.clientname"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="clientsList"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="clientname"
                  @input="fetchClientSitesForSettings"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Site Name -->
          <b-col
            v-if="cameraData.is_active || userData.group === 'Management' || userData.group === 'EyeTrax_Admin'"
            md="6"
          >
            <b-form-group
              label="Client Site"
              label-for="sitename"
            >
              <validation-provider
                #default="{ errors }"
                name="sitename"
                rules="required"
              >
                <v-select
                  v-model="cameraData.sitename"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="clientSitesList"
                  :reduce="val => val.value"
                  input-id="sitename"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div
              v-if="$can('update', 'clients')"
              class="d-flex align-items-center justify-content-end"
            >
              <b-button
                variant="primary"
                @click="isAddNewClientSitesSidebarActive = true"
              >
                <feather-icon icon="UserPlusIcon" /><span class="text-nowrap ml-1">Add Site</span>
              </b-button>
            </div>
          </b-col>

          <!-- Field: location name -->
          <b-col
            v-if="cameraData.is_active || userData.group === 'Management' || userData.group === 'EyeTrax_Admin'"
            md="6"
          >
            <b-form-group
              label="Location"
              label-for="location_name"
            >
              <validation-provider
                #default="{ errors }"
                name="location_name"
                rules="required"
              >
                <b-form-input
                  id="location_name"
                  v-model="cameraData.location_name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-if="userData.group === 'Management' || userData.group === 'EyeTrax_Admin'"
        >
          <!-- Field: Customer Info -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Customer Info"
              label-for="customer_info"
            >
              <validation-provider
                #default="{ errors }"
                name="customer_info"
              >
                <b-form-textarea
                  id="customer_info"
                  v-model="cameraData.customer_info"
                  rows="3"
                  max-rows="6"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: Notes -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Notes"
              label-for="note"
            >
              <validation-provider
                #default="{ errors }"
                name="note"
                rules="required"
              >
                <b-form-textarea
                  id="note"
                  v-model="cameraData.note"
                  rows="3"
                  max-rows="6"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-if="(userData.group === 'Management' || userData.group === 'EyeTrax_Admin') && cameraData.is_online"
          class="mt-2"
        >
          <!-- Field: Status -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Status"
              label-for="isActiveSetter"
            >
              <validation-provider
                #default="{ errors }"
                name="isActiveSetter"
                rules="required"
              >
                <v-select
                  v-model="isActiveSetter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="isActiveSetter"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
        </b-row>
        <!-- Save / Remove Buttons -->
        <b-row class="mt-2">
          <b-col
            v-if="cameraData.is_active || userData.group === 'Management' || userData.group === 'EyeTrax_Admin' || userData.group === 'ClientAdmins'"
            md="4"
          >
            <!-- Action Buttons -->
            <b-button
              v-if="$can('update', 'cameras') || $can('update', 'camerasmanagement')"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              block
              type="submit"
            >
              Save Changes
            </b-button>
          </b-col>
          <b-col
            v-if="userData.group === 'Management' || userData.group === 'EyeTrax_Admin'"
            md="4"
          >
            <!-- Action Buttons -->
            <b-button
              v-if="$can('delete', 'cameras') || $can('delete', 'camerasmanagement')"
              variant="danger"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              block
              @click="deleteCamera"
            >
              Delete Camera
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- Viewer Modal -->
    <b-modal
      id="vmodal"
      ref="vmodal"
      ok-only
      ok-title="Close"
      centered
      size="lg"
      title="Unit Number Error"
      @close="showErrorModal = false"
    >
      The Unit ID must be a integer from 1 to 2999 or the same as the Camera ID.
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { getUserData } from '@/auth/utils'
// Notification
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { ref } from '@vue/composition-api'
import { required, email } from '@validations'
import {
  BButton, BMedia, BRow, BCol, BFormGroup, BFormInput, BForm, BFormTextarea, BBadge, BModal, VBModal, BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import ability from '../../../../libs/acl/ability'
import ClientSitesListAddNew from '../../clients/clientsites-list/ClientSitesListAddNew.vue'
import useClientSitesList from '../../clients/clientsites-list/useClientSitesList'

export default {
  components: {
    BButton,
    BMedia,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BBadge,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ClientSitesListAddNew,
    BModal,
    BFormCheckbox,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    cameraData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      // validation rules
      required,
      email,
      clients: [],
      clientsList: [],
      clientSitesList: [],
      showErrorModal: false,
    }
  },
  setup(props) {
    const userData = getUserData()
    const statusOptions = store.state.appSettings.is_active
    const cellProviderOptions = store.state.appSettings.cell_phone_carrier
    const controllerBOMOptions = store.state.appSettings.camera_controller_BOM_options
    const powerOptions = store.state.appSettings.camera_power_options
    const devicePathOptions = store.state.appSettings.camera_device_path_options
    const hasPanTiltOptions = store.state.appSettings.camera_has_pan_tilt_options
    const captureIntervalOptions = store.state.appSettings.camera_capture_interval_options
    const motionDetectionEnabledOptions = store.state.appSettings.camera_motion_detection_enabled_options
    const motionDetectionIntervalOptions = store.state.appSettings.camera_motion_detection_interval_options
    const heartbeatIntervalOptions = store.state.appSettings.camera_heartbeat_interval_options
    const timeZoneOptions = store.state.appSettings.camera_time_zone_options
    const imageDelayOptions = []
    const imageResolutionOptions = store.state.appSettings.camera_image_resolution_options
    const videoLengthOptions = []
    const videoResolutionOptions = store.state.appSettings.camera_video_resolution_options
    const expiryDaysOptions = store.state.appSettings.camera_expiry_day_options
    const cameraTypeOptions = store.state.appSettings.camera_type_options

    const originalClientName = props.cameraData.clientname
    console.log(`originalClientName: ${originalClientName}`)
    const isActiveSetter = props.cameraData.is_active

    // generate the list of year built options
    const d = new Date()
    const n = d.getFullYear()
    const yearBuiltList = []
    console.log(store.state.appSettings.year_built_start_year)
    // console.log(n)
    // console.log(store.state.appSettings.year_built_start_year)
    for (let index = n; index >= store.state.appSettings.year_built_start_year; index -= 1) {
      yearBuiltList.push({ label: index.toString(), value: index.toString() })
    }
    console.log(yearBuiltList)

    const times = z => f => {
      const iter = i => {
        if (i === z) return
        f(i)
        iter(i + 1)
      }
      return iter(0)
    }
    // Set the image delay options
    times(30)(i => {
      const num = i + 1
      imageDelayOptions.push({ label: `${num} Seconds`, value: `${num}`, default: false })
    })
    // Set the video length options
    times(60)(i => {
      const num = i + 1
      videoLengthOptions.push({ label: `${num} Seconds`, value: `${num}`, default: false })
    })
    console.log(imageDelayOptions)
    console.log(videoLengthOptions)

    // *******************************************************
    // Client Side Add New Sidebar related vars and functions
    const isAddNewClientSitesSidebarActive = ref(false)

    const {
      fetchClientSites,
      tableColumns,
      perPage,
      currentPage,
      totalClientSites,
      clientSitesList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refClientSitesListTable,
      refetchData,
    } = useClientSitesList(props.cameraData.clientname)

    // *******************************************************

    return {
      isAddNewClientSitesSidebarActive,
      userData,
      avatarText,
      statusOptions,
      cellProviderOptions,
      yearBuiltList,
      controllerBOMOptions,
      powerOptions,
      devicePathOptions,
      hasPanTiltOptions,
      captureIntervalOptions,
      motionDetectionEnabledOptions,
      motionDetectionIntervalOptions,
      heartbeatIntervalOptions,
      timeZoneOptions,
      imageDelayOptions,
      imageResolutionOptions,
      videoLengthOptions,
      videoResolutionOptions,
      expiryDaysOptions,
      cameraTypeOptions,
      originalClientName,
      isActiveSetter,

      fetchClientSites,
      tableColumns,
      perPage,
      currentPage,
      totalClientSites,
      clientSitesList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refClientSitesListTable,
      refetchData,
    }
  },
  created() {
    // if (this.cameraIDProp !== '') {
    //   this.cameraData.client_name = this.cameraIDProp
    // }
    // console.log('unit_id')
    // console.log(typeof this.cameraData.unit_id === 'undefined')
    // console.log(this.cameraData.unit_id === null)
    // console.log(this.cameraData.unit_id.trim() === '')
    // console.log(/^\d+$/.test(this.cameraData.unit_id))
    // console.log(this.cameraData.unit_id > 2999)
    if (typeof this.cameraData.unit_id === 'undefined' || this.cameraData.unit_id === null || this.cameraData.unit_id === '' || !/^\d+$/.test(this.cameraData.unit_id) || this.cameraData.unit_id > 2999) {
      this.cameraData.unit_id = this.cameraData.camera_id
    }
    // Need to get Clients list
    this.clientsList = []
    if (ability.can('read', 'clients') || ability.can('read', 'clients')) {
      const qvars = {
        forceRefresh: true,
        q: '',
        perPage: 10,
        page: 1,
        sortBy: 'display_name',
        sortDesc: '',
        status: null,
        service: 'eyetrax',
      }
      store
        .dispatch('app-client/fetchClients', qvars)
        .then(response => {
          const { clients } = response.data
          clients.forEach(item => {
            this.clientsList.push({ label: item.display_name, value: item.clientname })
          })
          console.log('clientsList')
          console.log(this.clientsList)
          this.fetchClientSitesForSettings()
        })
        .catch(error => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching clients list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  },
  methods: {
    validateForm() {
      // Temporary Add: Adding additional fields to data set that will be included in for at later date
      this.cameraData.is_onboarding = false

      this.$refs.observer.validate().then(success => {
        if (success && (ability.can('update', 'cameras') || ability.can('update', 'camerasmanagement'))) {
          console.log('form validated')
          // Determine client_display name
          this.clientsList.forEach(item => {
            if (this.cameraData.clientname === item.value) {
              this.cameraData.client_display_name = item.label
            }
          })
          console.log(this.cameraData)
          const qvars = this.cameraData
          this.cameraData.is_active = this.isActiveSetter
          qvars.is_active_new = this.isActiveSetter
          // if (this.isActiveSetter !== this.cameraData.is_active) {
          //   qvars.is_active_new = this.isActiveSetter
          // }
          if ((typeof this.cameraData.unit_id === 'undefined' || this.cameraData.unit_id === null || !/^\d+$/.test(this.cameraData.unit_id) || (this.cameraData.unit_id > 2999 && this.cameraData.unit_id !== this.cameraData.camera_id)) && this.cameraData.unit_id !== '') {
            // Show a warning modal and do not proceed with save
            this.showViewerModal = true
            this.$refs.vmodal.show()
          } else {
            if (this.cameraData.unit_id.trim() === '') {
              this.cameraData.unit_id = this.cameraData.camera_id
            }

            qvars.orig_cameraData = this.cameraData
            this.$emit('goToTab', 'settings')

            store.dispatch('app-camera/updateCamera', qvars)
              .then(response => {
                console.log(response)
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Camera Updated',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
              })
              .catch(error => {
                console.log(error)
                let theErrorStr = 'The following error(s) where received:\n'
                if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined' && typeof error.response.data.errors !== 'undefined') {
                  error.response.data.errors.forEach(item => {
                    theErrorStr += `${item.message}\n`
                  })
                } else if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined' && typeof error.response.data.message !== 'undefined') {
                  theErrorStr = error.response.data.message
                } else {
                  theErrorStr += `${error}\n`
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error updating Camera information.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: theErrorStr,
                  },
                })
              })
          }
        }
      })
    },
    deleteCamera() {
      console.log('Delete Camera')
      if (ability.can('delete', 'cameras') || ability.can('delete', 'camerasmanagement')) {
        this.$bvModal
          .msgBoxConfirm(`Please confirm that you want to delete camera ${this.cameraData.sitename}, ${this.cameraData.location_name} ${this.cameraData.unit_number}`, {
            title: 'Delete Camera',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              const qvars = {
                camera_id: this.cameraData.camera_id,
              }
              store
                .dispatch('app-camera/deleteCamera', qvars)
                .then(response => {
                  console.log(response)
                  let nextRout = { name: 'apps-cameraconsole-list' }
                  if (ability.can('delete', 'camerasmanagement')) {
                    nextRout = { name: 'apps-cameras-management-list' }
                  } else if (ability.can('delete', 'cameras')) {
                    nextRout = { name: 'apps-cameraconsole-list' }
                  }
                  // Route user
                  this.$router.replace(nextRout)
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Camera deleted successfully.',
                          icon: 'CheckSquareIcon',
                          variant: 'success',
                        },
                      })
                    })
                })
                .catch(error => {
                  console.log(error)
                  let theErrorStr = 'The following error(s) where received:\n'
                  if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined' && typeof error.response.data.message !== 'undefined') {
                    theErrorStr = error.response.data.message
                  } else {
                    theErrorStr += `${error}\n`
                  }
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error deleting Camera',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: theErrorStr,
                    },
                  })
                })
            }
          })
      }
    },
    refreshClientSitesList(newSite) {
      console.log(newSite)
      this.cameraData.sitename = newSite
      this.fetchClientSitesForSettings()
    },
    fetchClientSitesForSettings() {
      console.log('fetchClientSitesForSettings')
      if (ability.can('read', 'clients') || ability.can('read', 'clients')) {
        if (this.originalClientName !== this.cameraData.clientname) {
          this.cameraData.sitename = null
          this.cameraData.location_name = null
          this.originalClientName = this.cameraData.clientname
        }
        // this.cameraData.sitename = ''
        const qvars = {
          forceRefresh: store.state.clients.fetchingClientSitesList,
          q: '',
          perPage: 10,
          page: 1,
          sortBy: 'sitename',
          sortDesc: true,
          status: null,
          clientname: this.cameraData.clientname,
        }
        this.clientSitesList = []
        store
          .dispatch('app-client/fetchClientSites', qvars)
          .then(response => {
            const { clientSites } = response.data
            // console.log(response.data)
            // console.log(clientSites)
            clientSites.forEach(item => {
              this.clientSitesList.push({ label: item.sitename, value: item.sitename })
            })
            // console.log(this.clientSitesList)
          })
          .catch(error => {
            console.log(error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching client sites list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
