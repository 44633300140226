var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("update", "cameras") ||
    _vm.$can("update", "camerasmanagement")
    ? _c(
        "div",
        [
          _c("b-media", { staticClass: "mb-2" }, [
            _c(
              "h4",
              { staticClass: "mb-1" },
              [
                _c("feather-icon", {
                  staticClass: "mr-50",
                  attrs: { icon: "CameraIcon", size: "22" }
                }),
                _vm._v(
                  " Camera Notifications -- " +
                    _vm._s(_vm.cameraData.sitename) +
                    ", " +
                    _vm._s(_vm.cameraData.location_name)
                ),
                _c("br")
              ],
              1
            ),
            _c("small", [
              _vm._v("Camera: " + _vm._s(_vm.cameraData.unit_number))
            ]),
            _c("br")
          ]),
          _c("validation-observer", {
            ref: "observer",
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var handleSubmit = ref.handleSubmit
                    return [
                      _c(
                        "b-form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return handleSubmit(_vm.validateForm)
                            }
                          }
                        },
                        [
                          _c(
                            "b-row",
                            { staticClass: "mt-2 mb-1 border-bottom-primary" },
                            [
                              _c("b-col", [
                                _c("h4", [_vm._v("Email Notifications")])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-2" },
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: {
                                        id: "send_email_motion_only",
                                        name: "send_email_motion_only"
                                      },
                                      model: {
                                        value:
                                          _vm.cameraData.notification
                                            .send_email_motion_only,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.cameraData.notification,
                                            "send_email_motion_only",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "cameraData.notification.send_email_motion_only"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " Send email on Motion Detection Only "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-2" },
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: {
                                        id: "send_email_after_hours",
                                        name: "send_email_after_hours"
                                      },
                                      model: {
                                        value:
                                          _vm.cameraData.notification
                                            .send_email_after_hours,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.cameraData.notification,
                                            "send_email_after_hours",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "cameraData.notification.send_email_after_hours"
                                      }
                                    },
                                    [_vm._v(" Send 6PM to 6AM Only ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: {
                                        id: "attach_image_on_emails",
                                        name: "attach_image_on_emails"
                                      },
                                      model: {
                                        value:
                                          _vm.cameraData.notification
                                            .attach_image_on_emails,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.cameraData.notification,
                                            "attach_image_on_emails",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "cameraData.notification.attach_image_on_emails"
                                      }
                                    },
                                    [_vm._v(" Attach Images to Email ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-2" },
                            [
                              _c(
                                "b-col",
                                [
                                  _c("b-table", {
                                    staticClass: "mb-0",
                                    attrs: {
                                      striped: "",
                                      responsive: "",
                                      items: _vm.emailNotificationsList
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "cell(fullName)",
                                          fn: function(data) {
                                            return [
                                              _vm._v(
                                                " " + _vm._s(data.value) + " "
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "cell(emailAddress)",
                                          fn: function(data) {
                                            return [
                                              _vm._v(
                                                " " + _vm._s(data.value) + " "
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "cell()",
                                          fn: function(data) {
                                            return [
                                              _c("b-form-checkbox", {
                                                attrs: {
                                                  id: data.value.name,
                                                  name: data.value.name,
                                                  checked: data.value.value,
                                                  value:
                                                    data.value.checkedvalue,
                                                  "unchecked-value":
                                                    data.value.uncheckedvalue
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.updateEmailList()
                                                  }
                                                },
                                                model: {
                                                  value: _vm.selectedEmails,
                                                  callback: function($$v) {
                                                    _vm.selectedEmails = $$v
                                                  },
                                                  expression: "selectedEmails"
                                                }
                                              })
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-2" },
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label:
                                          "Additional Emails: One email addresses per line",
                                        "label-for": "email_list_additional"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "email_list_additional"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-textarea", {
                                                    attrs: {
                                                      id:
                                                        "email_list_additional",
                                                      rows: "3",
                                                      "max-rows": "6"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.email_list_additional_display,
                                                      callback: function($$v) {
                                                        _vm.email_list_additional_display = $$v
                                                      },
                                                      expression:
                                                        "email_list_additional_display"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-3 mb-1 border-bottom-primary" },
                            [
                              _c("b-col", [
                                _c("h4", [_vm._v("SMS Notifications")])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-2" },
                            [
                              _c("b-col", [
                                _vm._v(
                                  " NOTE: SMS messages are sent on Motion Detection Only "
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-2" },
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: {
                                        id: "send_sms_after_hours",
                                        name: "send_sms_after_hours"
                                      },
                                      model: {
                                        value:
                                          _vm.cameraData.notification
                                            .send_sms_after_hours,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.cameraData.notification,
                                            "send_sms_after_hours",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "cameraData.notification.send_sms_after_hours"
                                      }
                                    },
                                    [_vm._v(" Send 6PM to 6AM Only ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-2" },
                            [
                              _c(
                                "b-col",
                                [
                                  _c("b-table", {
                                    staticClass: "mb-0",
                                    attrs: {
                                      striped: "",
                                      responsive: "",
                                      items: _vm.smsNotificationsList
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "cell(fullName)",
                                          fn: function(data) {
                                            return [
                                              _vm._v(
                                                " " + _vm._s(data.value) + " "
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "cell(phoneNumber)",
                                          fn: function(data) {
                                            return [
                                              _vm._v(
                                                " " + _vm._s(data.value) + " "
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "cell()",
                                          fn: function(data) {
                                            return [
                                              _c("b-form-checkbox", {
                                                attrs: {
                                                  id: data.value.name,
                                                  name: data.value.name,
                                                  checked: data.value.value,
                                                  value:
                                                    data.value.checkedvalue,
                                                  "unchecked-value":
                                                    data.value.uncheckedvalue
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.updateSMSList()
                                                  }
                                                },
                                                model: {
                                                  value: _vm.selectedSMS,
                                                  callback: function($$v) {
                                                    _vm.selectedSMS = $$v
                                                  },
                                                  expression: "selectedSMS"
                                                }
                                              })
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-2" },
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label:
                                          "Additional Phone Numbers: One phone number per line",
                                        "label-for": "sms_list_additional"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: { name: "sms_list_additional" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-textarea", {
                                                    attrs: {
                                                      id: "sms_list_additional",
                                                      rows: "3",
                                                      "max-rows": "6"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.sms_list_additional_display,
                                                      callback: function($$v) {
                                                        _vm.sms_list_additional_display = $$v
                                                      },
                                                      expression:
                                                        "sms_list_additional_display"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-2" },
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "4" } },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                      attrs: {
                                        variant: "primary",
                                        block: "",
                                        type: "submit"
                                      }
                                    },
                                    [_vm._v(" Save Changes ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              2429128021
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }