var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("read", "cameras") || _vm.$can("read", "camerasmanagement")
    ? _c(
        "div",
        [
          _c("b-media", { staticClass: "mb-2" }, [
            _c(
              "h4",
              { staticClass: "mb-1" },
              [
                _c("feather-icon", {
                  staticClass: "mr-50",
                  attrs: { icon: "CameraIcon", size: "22" }
                }),
                _vm._v(
                  " Camera Scheduler -- " +
                    _vm._s(_vm.cameraData.sitename) +
                    ", " +
                    _vm._s(_vm.cameraData.location_name)
                ),
                _c("br")
              ],
              1
            ),
            _c("small", [
              _vm._v("Camera: " + _vm._s(_vm.cameraData.unit_number))
            ]),
            _c("br")
          ]),
          _vm.firmwareVersion >= 1.55
            ? _c("validation-observer", {
                ref: "observer",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var handleSubmit = ref.handleSubmit
                        return [
                          _c(
                            "b-form",
                            {
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return handleSubmit(_vm.validateMotionForm)
                                }
                              }
                            },
                            [
                              _c(
                                "b-row",
                                {
                                  staticClass: "mt-2 mb-1 border-bottom-primary"
                                },
                                [
                                  _c("b-col", [
                                    _c("h4", [
                                      _vm._v("Motion Detection Schedule")
                                    ])
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _vm.cameraData.is_active
                                    ? _c(
                                        "b-col",
                                        { attrs: { md: "3" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Motion Detection",
                                                "label-for":
                                                  "is_motion_detection_enabled"
                                              }
                                            },
                                            [
                                              _c("b-form-checkbox", {
                                                attrs: {
                                                  name: "check-button",
                                                  switch: "",
                                                  inline: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.cameraData.settings
                                                      .is_motion_detection_enabled,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.cameraData.settings,
                                                      "is_motion_detection_enabled",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "cameraData.settings.is_motion_detection_enabled"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  (_vm.userData.group === "Management" ||
                                    _vm.userData.group === "EyeTrax_Admin") &&
                                  _vm.cameraData.settings
                                    .is_motion_detection_enabled
                                    ? _c(
                                        "b-col",
                                        { attrs: { md: "3" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Video Length",
                                                "label-for": "image_delay"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "video_length",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              options:
                                                                _vm.videoLengthOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              clearable: false,
                                                              "input-id":
                                                                "video_length"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.cameraData
                                                                  .configuration
                                                                  .video_length,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.cameraData
                                                                    .configuration,
                                                                  "video_length",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "cameraData.configuration.video_length"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm.cameraData.settings
                                .is_motion_detection_enabled
                                ? _c(
                                    "b-row",
                                    { staticClass: "mb-1" },
                                    [
                                      _c("b-col", [
                                        _c("small", [
                                          _vm._v("Detection Schedule")
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.cameraData.settings
                                .is_motion_detection_enabled
                                ? _c("b-table", {
                                    attrs: {
                                      small: "",
                                      fields: _vm.motionFields,
                                      items: _vm.motionItems,
                                      striped: "",
                                      responsive: ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "cell(day)",
                                          fn: function(data) {
                                            return [
                                              _vm._v(
                                                " " + _vm._s(data.value) + " "
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "cell(start_time)",
                                          fn: function(data) {
                                            return [
                                              _c("b-form-timepicker", {
                                                staticClass: "w-100",
                                                attrs: {
                                                  id:
                                                    data.item.day +
                                                    "_start_time_motion",
                                                  placeholder: "start time",
                                                  locale: "en"
                                                },
                                                model: {
                                                  value: data.item.start_time,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      data.item,
                                                      "start_time",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "data.item.start_time"
                                                }
                                              })
                                            ]
                                          }
                                        },
                                        {
                                          key: "cell(end_time)",
                                          fn: function(data) {
                                            return [
                                              _c("b-form-timepicker", {
                                                staticClass: "w-100",
                                                attrs: {
                                                  id:
                                                    data.item.day +
                                                    "_end_time_motion",
                                                  placeholder: "end time",
                                                  locale: "en"
                                                },
                                                model: {
                                                  value: data.item.end_time,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      data.item,
                                                      "end_time",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "data.item.end_time"
                                                }
                                              })
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                : _vm._e(),
                              _c(
                                "b-row",
                                { staticClass: "mt-2" },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "4" } },
                                    [
                                      _vm.$can("update", "cameras") ||
                                      _vm.$can("update", "camerasmanagement")
                                        ? _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                              attrs: {
                                                variant: "primary",
                                                block: "",
                                                type: "submit"
                                              }
                                            },
                                            [_vm._v(" Save Changes ")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1340978266
                )
              })
            : _vm._e(),
          _vm.firmwareVersion >= 1.55 &&
          _vm.cameraData.device_path === "192.168.1.110"
            ? _c("validation-observer", {
                ref: "observer",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var handleSubmit = ref.handleSubmit
                        return [
                          _c(
                            "b-form",
                            {
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return handleSubmit(_vm.validateFTPMotionForm)
                                }
                              }
                            },
                            [
                              _c(
                                "b-row",
                                {
                                  staticClass: "mt-2 mb-1 border-bottom-primary"
                                },
                                [
                                  _c("b-col", [
                                    _c("h4", [
                                      _vm._v("Pixel Motion Detection Schedule")
                                    ])
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _vm.cameraData.is_active
                                    ? _c(
                                        "b-col",
                                        { attrs: { md: "3" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Pixel Motion Detection",
                                                "label-for":
                                                  "ftp_monitor_enabled"
                                              }
                                            },
                                            [
                                              _c("b-form-checkbox", {
                                                attrs: {
                                                  name: "check-button",
                                                  switch: "",
                                                  inline: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.cameraData.ftp_monitor
                                                      .enabled,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.cameraData
                                                        .ftp_monitor,
                                                      "enabled",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "cameraData.ftp_monitor.enabled"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  (_vm.userData.group === "Management" ||
                                    _vm.userData.group === "EyeTrax_Admin") &&
                                  _vm.cameraData.ftp_monitor.enabled
                                    ? _c(
                                        "b-col",
                                        { attrs: { md: "3" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Video Length",
                                                "label-for": "video_length1"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "video_length1",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              options:
                                                                _vm.videoLengthOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              clearable: false,
                                                              "input-id":
                                                                "video_length1"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.cameraData
                                                                  .ftp_monitor
                                                                  .video_duration_sec,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.cameraData
                                                                    .ftp_monitor,
                                                                  "video_duration_sec",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "cameraData.ftp_monitor.video_duration_sec"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm.cameraData.ftp_monitor.enabled
                                ? _c(
                                    "b-row",
                                    { staticClass: "mb-1" },
                                    [
                                      _c("b-col", [
                                        _c("small", [
                                          _vm._v("Detection Schedule")
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.cameraData.ftp_monitor.enabled
                                ? _c("b-table", {
                                    attrs: {
                                      small: "",
                                      fields: _vm.motionFields,
                                      items: _vm.ftpMotionItems,
                                      striped: "",
                                      responsive: ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "cell(day)",
                                          fn: function(data) {
                                            return [
                                              _vm._v(
                                                " " + _vm._s(data.value) + " "
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "cell(start_time)",
                                          fn: function(data) {
                                            return [
                                              _c("b-form-timepicker", {
                                                staticClass: "w-100",
                                                attrs: {
                                                  id:
                                                    data.item.day +
                                                    "_start_time_ftp_motion",
                                                  placeholder: "start time",
                                                  locale: "en"
                                                },
                                                model: {
                                                  value: data.item.start_time,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      data.item,
                                                      "start_time",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "data.item.start_time"
                                                }
                                              })
                                            ]
                                          }
                                        },
                                        {
                                          key: "cell(end_time)",
                                          fn: function(data) {
                                            return [
                                              _c("b-form-timepicker", {
                                                staticClass: "w-100",
                                                attrs: {
                                                  id:
                                                    data.item.day +
                                                    "_end_time_ftp_motion",
                                                  placeholder: "end time",
                                                  locale: "en"
                                                },
                                                model: {
                                                  value: data.item.end_time,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      data.item,
                                                      "end_time",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "data.item.end_time"
                                                }
                                              })
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                : _vm._e(),
                              _c(
                                "b-row",
                                { staticClass: "mt-2" },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "4" } },
                                    [
                                      _vm.$can("update", "cameras") ||
                                      _vm.$can("update", "camerasmanagement")
                                        ? _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                              attrs: {
                                                variant: "primary",
                                                block: "",
                                                type: "submit"
                                              }
                                            },
                                            [_vm._v(" Save Changes ")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3667689895
                )
              })
            : _vm._e(),
          _c("validation-observer", {
            ref: "observer",
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var handleSubmit = ref.handleSubmit
                    return [
                      _c(
                        "b-form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return handleSubmit(_vm.validateForm)
                            }
                          }
                        },
                        [
                          _c(
                            "b-row",
                            { staticClass: "mt-4 mb-1 border-bottom-primary" },
                            [
                              _c("b-col", [
                                _c("h4", [_vm._v("Imaging Schedule")])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mb-1" },
                            [
                              _c("b-col", [
                                _c("small", [
                                  _vm._v("Detailed Imaging Schedule")
                                ])
                              ])
                            ],
                            1
                          ),
                          _c("b-table", {
                            attrs: {
                              small: "",
                              fields: _vm.fields,
                              items: _vm.items,
                              striped: "",
                              responsive: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "cell(day)",
                                  fn: function(data) {
                                    return [
                                      _vm._v(" " + _vm._s(data.value) + " ")
                                    ]
                                  }
                                },
                                {
                                  key: "cell()",
                                  fn: function(data) {
                                    return [
                                      _c("b-form-select", {
                                        staticClass: "w-100",
                                        attrs: {
                                          id: data.item.day + "_interval",
                                          options: _vm.intervalOptions,
                                          clearable: false,
                                          "input-id":
                                            data.item.day + "_interval"
                                        },
                                        model: {
                                          value: data.item.interval,
                                          callback: function($$v) {
                                            _vm.$set(data.item, "interval", $$v)
                                          },
                                          expression: "data.item.interval"
                                        }
                                      })
                                    ]
                                  }
                                },
                                {
                                  key: "cell(start_time)",
                                  fn: function(data) {
                                    return [
                                      _c("b-form-timepicker", {
                                        staticClass: "w-100",
                                        attrs: {
                                          id: data.item.day + "_start_time",
                                          placeholder: "start time",
                                          locale: "en"
                                        },
                                        model: {
                                          value: data.item.start_time,
                                          callback: function($$v) {
                                            _vm.$set(
                                              data.item,
                                              "start_time",
                                              $$v
                                            )
                                          },
                                          expression: "data.item.start_time"
                                        }
                                      })
                                    ]
                                  }
                                },
                                {
                                  key: "cell(end_time)",
                                  fn: function(data) {
                                    return [
                                      _c("b-form-timepicker", {
                                        staticClass: "w-100",
                                        attrs: {
                                          id: data.item.day + "_end_time",
                                          placeholder: "end time",
                                          locale: "en"
                                        },
                                        model: {
                                          value: data.item.end_time,
                                          callback: function($$v) {
                                            _vm.$set(data.item, "end_time", $$v)
                                          },
                                          expression: "data.item.end_time"
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _c(
                            "b-row",
                            { staticClass: "mt-2" },
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "4" } },
                                [
                                  _vm.$can("update", "cameras") ||
                                  _vm.$can("update", "camerasmanagement")
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                          attrs: {
                                            variant: "primary",
                                            block: "",
                                            type: "submit"
                                          }
                                        },
                                        [_vm._v(" Save Changes ")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              1430579887
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }