<template>
  <div
    v-if="$can('read', 'cameras') || $can('read', 'camerasmanagement')"
  >

    <!-- Media -->
    <b-media class="mb-2">
      <h4 class="mb-1">
        <feather-icon
          icon="CameraIcon"
          size="22"
          class="mr-50"
        />
        Camera Scheduler -- {{ cameraData.sitename }}, {{ cameraData.location_name }}<br>
      </h4>
      <small>Camera: {{ cameraData.unit_number }}</small><br>
    </b-media>
    <!-- Motion Detection Scheduler -->
    <validation-observer
      v-if="firmwareVersion >= 1.55"
      ref="observer"
      #default="{ handleSubmit }"
    >
      <b-form
        @submit.prevent="handleSubmit(validateMotionForm)"
      >
        <b-row class="mt-2 mb-1 border-bottom-primary">
          <b-col>
            <h4>Motion Detection Schedule</h4>
          </b-col>
        </b-row>
        <!-- Motion Interval -->
        <b-row>
          <!-- Motion Detection Enabled -->
          <b-col
            v-if="cameraData.is_active"
            md="3"
          >
            <b-form-group
              label="Motion Detection"
              label-for="is_motion_detection_enabled"
            >
              <b-form-checkbox
                v-model="cameraData.settings.is_motion_detection_enabled"
                name="check-button"
                switch
                inline
              />
            </b-form-group>
          </b-col>
          <!-- Video Length -->
          <b-col
            v-if="(userData.group === 'Management' || userData.group === 'EyeTrax_Admin') && cameraData.settings.is_motion_detection_enabled"
            md="3"
          >
            <b-form-group
              label="Video Length"
              label-for="image_delay"
            >
              <validation-provider
                #default="{ errors }"
                name="video_length"
                rules="required"
              >
                <v-select
                  v-model="cameraData.configuration.video_length"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="videoLengthOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="video_length"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-if="cameraData.settings.is_motion_detection_enabled"
          class="mb-1"
        >
          <b-col>
            <small>Detection Schedule</small>
          </b-col>
        </b-row>
        <b-table
          v-if="cameraData.settings.is_motion_detection_enabled"
          small
          :fields="motionFields"
          :items="motionItems"
          striped
          responsive
        >
          <!-- Day column -->
          <template #cell(day)="data">
            {{ data.value }}
          </template>

          <template #cell(start_time)="data">
            <b-form-timepicker
              :id="`${data.item.day}_start_time_motion`"
              v-model="data.item.start_time"
              placeholder="start time"
              class="w-100"
              locale="en"
            />
          </template>

          <template #cell(end_time)="data">
            <b-form-timepicker
              :id="`${data.item.day}_end_time_motion`"
              v-model="data.item.end_time"
              placeholder="end time"
              class="w-100"
              locale="en"
            />
          </template>
        </b-table>
        <b-row class="mt-2">
          <b-col
            md="4"
          >
            <!-- Action Buttons -->
            <b-button
              v-if="$can('update', 'cameras') || $can('update', 'camerasmanagement')"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              block
              type="submit"
            >
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- Pixel Motion Detection Scheduler -->
    <validation-observer
      v-if="firmwareVersion >= 1.55 && cameraData.device_path === '192.168.1.110'"
      ref="observer"
      #default="{ handleSubmit }"
    >
      <b-form
        @submit.prevent="handleSubmit(validateFTPMotionForm)"
      >
        <b-row class="mt-2 mb-1 border-bottom-primary">
          <b-col>
            <h4>Pixel Motion Detection Schedule</h4>
          </b-col>
        </b-row>
        <!-- Motion Interval -->
        <b-row>
          <!-- Motion Detection Enabled -->
          <b-col
            v-if="cameraData.is_active"
            md="3"
          >
            <b-form-group
              label="Pixel Motion Detection"
              label-for="ftp_monitor_enabled"
            >
              <b-form-checkbox
                v-model="cameraData.ftp_monitor.enabled"
                name="check-button"
                switch
                inline
              />
            </b-form-group>
          </b-col>
          <!-- Video Length -->
          <b-col
            v-if="(userData.group === 'Management' || userData.group === 'EyeTrax_Admin') && cameraData.ftp_monitor.enabled"
            md="3"
          >
            <b-form-group
              label="Video Length"
              label-for="video_length1"
            >
              <validation-provider
                #default="{ errors }"
                name="video_length1"
                rules="required"
              >
                <v-select
                  v-model="cameraData.ftp_monitor.video_duration_sec"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="videoLengthOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="video_length1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-if="cameraData.ftp_monitor.enabled"
          class="mb-1"
        >
          <b-col>
            <small>Detection Schedule</small>
          </b-col>
        </b-row>
        <b-table
          v-if="cameraData.ftp_monitor.enabled"
          small
          :fields="motionFields"
          :items="ftpMotionItems"
          striped
          responsive
        >
          <!-- Day column -->
          <template #cell(day)="data">
            {{ data.value }}
          </template>

          <template #cell(start_time)="data">
            <b-form-timepicker
              :id="`${data.item.day}_start_time_ftp_motion`"
              v-model="data.item.start_time"
              placeholder="start time"
              class="w-100"
              locale="en"
            />
          </template>

          <template #cell(end_time)="data">
            <b-form-timepicker
              :id="`${data.item.day}_end_time_ftp_motion`"
              v-model="data.item.end_time"
              placeholder="end time"
              class="w-100"
              locale="en"
            />
          </template>
        </b-table>
        <b-row class="mt-2">
          <b-col
            md="4"
          >
            <!-- Action Buttons -->
            <b-button
              v-if="$can('update', 'cameras') || $can('update', 'camerasmanagement')"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              block
              type="submit"
            >
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- Imaging Scheduler -->
    <validation-observer
      ref="observer"
      #default="{ handleSubmit }"
    >
      <b-form
        @submit.prevent="handleSubmit(validateForm)"
      >
        <b-row class="mt-4 mb-1 border-bottom-primary">
          <b-col>
            <h4>Imaging Schedule</h4>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col>
            <small>Detailed Imaging Schedule</small>
          </b-col>
        </b-row>
        <b-table
          small
          :fields="fields"
          :items="items"
          striped
          responsive
        >
          <!-- Day column -->
          <template #cell(day)="data">
            {{ data.value }}
          </template>

          <template #cell()="data">
            <b-form-select
              :id="`${data.item.day}_interval`"
              v-model="data.item.interval"
              :options="intervalOptions"
              :clearable="false"
              class="w-100"
              :input-id="`${data.item.day}_interval`"
            />
            <!-- <v-select
              :id="`${data.item.day}_interval`"
              v-model="data.item.interval"
              :options="intervalOptions"
              :clearable="false"
              class="w-100"
              :reduce="val => val.value"
              :input-id="`${data.item.day}_interval`"
            /> -->
          </template>

          <template #cell(start_time)="data">
            <b-form-timepicker
              :id="`${data.item.day}_start_time`"
              v-model="data.item.start_time"
              placeholder="start time"
              class="w-100"
              locale="en"
            />
          </template>

          <template #cell(end_time)="data">
            <b-form-timepicker
              :id="`${data.item.day}_end_time`"
              v-model="data.item.end_time"
              placeholder="end time"
              class="w-100"
              locale="en"
            />
          </template>
        </b-table>
        <b-row class="mt-2">
          <b-col
            md="4"
          >
            <!-- Action Buttons -->
            <b-button
              v-if="$can('update', 'cameras') || $can('update', 'camerasmanagement')"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              block
              type="submit"
            >
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BMedia, BRow, BCol, BTable, BFormTimepicker, BButton, BForm, BFormGroup, BFormSelect, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BMedia,
    BRow,
    BCol,
    BTable,
    BForm,
    BFormTimepicker,
    BButton,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormGroup,
    BFormCheckbox,
  },
  props: {
    cameraData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      originalCaptureInterval: null,
      originalSchedule: null,
      status: '',
      options: {},
      signalOptions: {},
      theImage: '',
      fields: [
        // A column that needs custom formatting
        { key: 'day', label: 'Day' },
        // A regular column
        { key: 'interval', label: 'Interval' },
        // A regular column
        { key: 'start_time', label: 'Start Time' },
        // A virtual column made up from two fields
        { key: 'end_time', label: 'End Time' },
      ],
      motionFields: [
        // A column that needs custom formatting
        { key: 'day', label: 'Day' },
        // A regular column
        { key: 'start_time', label: 'Start Time' },
        // A virtual column made up from two fields
        { key: 'end_time', label: 'End Time' },
      ],
      items: [
        {
          day_of_week: '0',
          day: 'Sunday',
          interval: '0',
          start_time: '',
          end_time: '',
        },
        {
          day_of_week: '1',
          day: 'Monday',
          interval: '0',
          start_time: '',
          end_time: '',
        },
        {
          day_of_week: '2',
          day: 'Tuesday',
          interval: '0',
          start_time: '',
          end_time: '',
        },
        {
          day_of_week: '3',
          day: 'Wednesday',
          interval: '0',
          start_time: '',
          end_time: '',
        },
        {
          day_of_week: '4',
          day: 'Thursday',
          interval: '0',
          start_time: '',
          end_time: '',
        },
        {
          day_of_week: '5',
          day: 'Friday',
          interval: '0',
          start_time: '',
          end_time: '',
        },
        {
          day_of_week: '6',
          day: 'Saturday',
          interval: '0',
          start_time: '',
          end_time: '',
        },
      ],
      motionItems: [
        {
          day_of_week: '0',
          day: 'Sunday',
          start_time: '',
          end_time: '',
        },
        {
          day_of_week: '1',
          day: 'Monday',
          start_time: '',
          end_time: '',
        },
        {
          day_of_week: '2',
          day: 'Tuesday',
          start_time: '',
          end_time: '',
        },
        {
          day_of_week: '3',
          day: 'Wednesday',
          start_time: '',
          end_time: '',
        },
        {
          day_of_week: '4',
          day: 'Thursday',
          start_time: '',
          end_time: '',
        },
        {
          day_of_week: '5',
          day: 'Friday',
          start_time: '',
          end_time: '',
        },
        {
          day_of_week: '6',
          day: 'Saturday',
          start_time: '',
          end_time: '',
        },
      ],
      ftpMotionItems: [
        {
          day_of_week: '0',
          day: 'Sunday',
          start_time: '8:00',
          end_time: '17:00',
        },
        {
          day_of_week: '1',
          day: 'Monday',
          start_time: '8:00',
          end_time: '17:00',
        },
        {
          day_of_week: '2',
          day: 'Tuesday',
          start_time: '8:00',
          end_time: '17:00',
        },
        {
          day_of_week: '3',
          day: 'Wednesday',
          start_time: '8:00',
          end_time: '17:00',
        },
        {
          day_of_week: '4',
          day: 'Thursday',
          start_time: '8:00',
          end_time: '17:00',
        },
        {
          day_of_week: '5',
          day: 'Friday',
          start_time: '8:00',
          end_time: '17:00',
        },
        {
          day_of_week: '6',
          day: 'Saturday',
          start_time: '8:00',
          end_time: '17:00',
        },
      ],
      firmwareVersion: 0,
    }
  },
  setup() {
    const userData = getUserData()
    const { camera_capture_interval_options } = store.state.appSettings
    // intervalOptions
    const intervalOptions = []
    camera_capture_interval_options.forEach(item => {
      intervalOptions.push({ text: item.label, value: item.value })
    })
    const captureIntervalOptions = store.state.appSettings.camera_capture_interval_options
    const motionDetectionEnabledOptions = store.state.appSettings.camera_motion_detection_enabled_options
    const videoLengthOptions = []

    // Set the video length options
    const times = z => f => {
      const iter = i => {
        if (i === z) return
        f(i)
        iter(i + 1)
      }
      return iter(0)
    }
    times(60)(i => {
      const num = i + 1
      videoLengthOptions.push({ label: `${num} Seconds`, value: `${num}`, default: false })
    })

    return {
      intervalOptions,
      captureIntervalOptions,
      motionDetectionEnabledOptions,
      videoLengthOptions,
      userData,
    }
  },
  created() {
    // Imaging Schedule
    this.originalCaptureInterval = this.cameraData.settings.capture_interval
    this.originalSchedule = this.cameraData.schedule
    console.log('this.cameraData.schedule')
    console.log(this.cameraData.schedule)
    this.setDetailedSchedule()
    // Motion Detection Schedule
    if (typeof this.cameraData.motion_schedule === 'undefined') {
      this.cameraData.motion_schedule = this.motionItems
    }
    this.originalMotionInterval = this.cameraData.settings.motion_interval
    this.originalMotionSchedule = this.cameraData.motion_schedule
    this.setDetailedMotionSchedule()
    // FTP Motion Detection Schedule
    if (typeof this.cameraData.ftp_monitor === 'undefined') {
      this.cameraData.ftp_monitor = {}
    }
    if (typeof this.cameraData.ftp_monitor.enabled === 'undefined') {
      this.cameraData.ftp_monitor.enabled = false
    }
    if (typeof this.cameraData.ftp_monitor.schedule === 'undefined' || this.cameraData.ftp_monitor.schedule === [] || this.cameraData.ftp_monitor.schedule.length === 0) {
      this.cameraData.ftp_monitor.schedule = new Array(this.ftpMotionItems)
    }
    this.originalFTPMotionInterval = this.cameraData.ftp_monitor.monitor_interval_sec
    this.originalFTPMotionSchedule = this.cameraData.ftp_monitor.schedule
    this.setDetailedFTPMotionSchedule()

    console.log(this.cameraData)
    console.log(this.items)
    // Determine numeric firmware version
    if (this.cameraData.current_status.firmware.version !== null && this.cameraData.current_status.firmware.version !== '') {
      const tmp = this.cameraData.current_status.firmware.version.replace('v', '').replace('V', '').split('.')
      this.firmwareVersion = Number(`${tmp[0]}.${tmp[1]}`)
    }
  },
  methods: {
    setDetailedSchedule() {
      this.cameraData.schedule.forEach(item => {
        this.items.forEach((item2, index) => {
          if (item.day_of_week === item2.day_of_week) {
            this.items[index].interval = item.interval
            if (typeof item.start_time === 'undefined' || item.start_time === '' || item.start_time === null) {
              this.items[index].start_time = '00:00'
            } else {
              this.items[index].start_time = item.start_time
            }
            if (typeof item.end_time === 'undefined' || item.end_time === '' || item.end_time === null) {
              this.items[index].end_time = '23:59'
            } else {
              this.items[index].end_time = item.end_time
            }
          }
        })
      })
    },
    setDetailedMotionSchedule() {
      console.log('setDetailedMotionSchedule')
      console.log(this.cameraData.motion_schedule)
      this.cameraData.motion_schedule.forEach(item => {
        this.motionItems.forEach((item2, index) => {
          if (item.day_of_week === item2.day_of_week) {
            if (typeof item.start_time === 'undefined' || item.start_time === '' || item.start_time === null) {
              this.motionItems[index].start_time = '00:00'
            } else {
              this.motionItems[index].start_time = item.start_time
            }
            if (typeof item.end_time === 'undefined' || item.end_time === '' || item.end_time === null) {
              this.motionItems[index].end_time = '23:59'
            } else {
              this.motionItems[index].end_time = item.end_time
            }
          }
        })
      })
    },
    validateForm() {
      // let tmp = {}
      // const newPermissionsArr = []
      console.log(this.cameraData)
      console.log(this.items)
      if (ability.can('update', 'cameras') || ability.can('update', 'camerasmanagement')) {
        this.cameraData.schedule = this.items
        console.log('form validated')
        console.log(this.cameraData)
        const cameraDataLimited = {}
        cameraDataLimited.camera_id = this.cameraData.camera_id
        cameraDataLimited.schedule = []
        this.cameraData.schedule.forEach(item => {
          const tmp = {}
          tmp.day_of_week = item.day_of_week
          tmp.interval = item.interval
          tmp.start_time = item.start_time
          tmp.end_time = item.end_time
          cameraDataLimited.schedule.push(tmp)
        })
        cameraDataLimited.orig_cameraData = this.cameraData
        console.log(cameraDataLimited)

        store.dispatch('app-camera/updateCamera', cameraDataLimited)
          .then(response => {
            console.log(response)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Camera Updated',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            console.log(error)
            let theErrorStr = 'The following error(s) where received:\n'
            if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined' && typeof error.response.data.errors !== 'undefined') {
              error.response.data.errors.forEach(item => {
                theErrorStr += `${item.message}\n`
              })
            } else {
              theErrorStr += `${error}\n`
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error updating Camera information.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: theErrorStr,
              },
            })
          })
      }
    },
    onPictureIntervalSelect(selectedVal) {
      console.log('onPictureIntervalSelect')
      console.log(selectedVal)
      if (this.$ability.can('update', 'cameras') || this.$ability.can('update', 'camerasmanagement')) {
        this.$bvModal
          .msgBoxConfirm('Selecting a Picture Interval will override and reset the Detailed Imaging Schedule. Are you sure you wish to proceed?', {
            title: 'Picture Interval Selection',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              console.log('Proceed')
              this.cameraData.schedule.forEach((item, index) => {
                this.cameraData.schedule[index].interval = selectedVal
                this.cameraData.schedule[index].start_time = '0:00'
                this.cameraData.schedule[index].end_time = '23:59'
              })
              console.log(this.cameraData.schedule)
              this.setDetailedSchedule()
            } else {
              this.cameraData.settings.capture_interval = this.originalCaptureInterval
            }
            setTimeout(() => {
              document.activeElement.blur()
            }, 300)
          })
      }
    },
    resetDetailedSchedule() {
      this.cameraData.schedule = this.originalSchedule
      this.setDetailedSchedule()
    },
    onMotionIntervalSelect(selectedVal) {
      console.log('onMotionIntervalSelect')
      console.log(selectedVal)
      if (this.$ability.can('update', 'cameras') || this.$ability.can('update', 'camerasmanagement')) {
        this.$bvModal
          .msgBoxConfirm('Selecting a Motion Detection Interval will override and reset the Detailed Imaging Schedule. Are you sure you wish to proceed?', {
            title: 'Motion Detection Interval Selection',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              console.log('Proceed')
              this.cameraData.motion_schedule.forEach((item, index) => {
                this.cameraData.motion_schedule[index].interval = selectedVal
                this.cameraData.motion_schedule[index].start_time = '0:00'
                this.cameraData.motion_schedule[index].end_time = '23:59'
              })
              console.log(this.cameraData.motion_schedule)
              this.setDetailedMotionSchedule()
            } else {
              this.cameraData.settings.motion_interval = this.originalMotionInterval
            }
          })
      }
    },
    validateMotionForm() {
      console.log('validateMotionForm')
      // let tmp = {}
      // const newPermissionsArr = []
      console.log(this.cameraData)
      console.log(this.motionItems)
      if (ability.can('update', 'cameras') || ability.can('update', 'camerasmanagement')) {
        this.cameraData.motion_schedule = this.motionItems
        console.log('form validated')
        console.log(this.cameraData)
        const cameraDataLimited = {}
        cameraDataLimited.camera_id = this.cameraData.camera_id
        cameraDataLimited.configuration = this.cameraData.configuration
        cameraDataLimited.configuration.video_length = this.cameraData.configuration.video_length
        cameraDataLimited.settings = this.cameraData.settings
        cameraDataLimited.settings.is_motion_detection_enabled = this.cameraData.settings.is_motion_detection_enabled
        cameraDataLimited.motion_schedule = []
        this.cameraData.motion_schedule.forEach(item => {
          const tmp = {}
          tmp.day_of_week = item.day_of_week
          tmp.start_time = item.start_time
          tmp.end_time = item.end_time
          cameraDataLimited.motion_schedule.push(tmp)
        })
        cameraDataLimited.orig_cameraData = this.cameraData
        console.log(cameraDataLimited)

        store.dispatch('app-camera/updateCamera', cameraDataLimited)
          .then(response => {
            console.log(response)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Camera Updated',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            console.log(error)
            let theErrorStr = 'The following error(s) where received:\n'
            if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined' && typeof error.response.data.errors !== 'undefined') {
              error.response.data.errors.forEach(item => {
                theErrorStr += `${item.message}\n`
              })
            } else {
              theErrorStr += `${error}\n`
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error updating Camera information.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: theErrorStr,
              },
            })
          })
      }
    },
    // FTP Motion Detection
    setDetailedFTPMotionSchedule() {
      console.log('setDetailedFTPMotionSchedule')
      console.log(this.cameraData.ftp_monitor.schedule)
      this.cameraData.ftp_monitor.schedule.forEach(item => {
        this.ftpMotionItems.forEach((item2, index) => {
          if (item.day_of_week === item2.day_of_week) {
            if (typeof item.start_time === 'undefined' || item.start_time === '' || item.start_time === null) {
              this.ftpMotionItems[index].start_time = '00:00'
            } else {
              this.ftpMotionItems[index].start_time = item.start_time
            }
            if (typeof item.end_time === 'undefined' || item.end_time === '' || item.end_time === null) {
              this.ftpMotionItems[index].end_time = '23:59'
            } else {
              this.ftpMotionItems[index].end_time = item.end_time
            }
          }
        })
      })
    },
    onFTPMotionIntervalSelect(selectedVal) {
      console.log('onFTPMotionIntervalSelect')
      console.log(selectedVal)
      if (this.$ability.can('update', 'cameras') || this.$ability.can('update', 'camerasmanagement')) {
        this.$bvModal
          .msgBoxConfirm('Selecting a Motion Detection Interval will override and reset the Pixel Detailed Imaging Schedule. Are you sure you wish to proceed?', {
            title: 'Pixel Motion Detection Interval Selection',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              console.log('Proceed')
              this.cameraData.ftp_monitor.schedule.forEach((item, index) => {
                this.cameraData.ftp_monitor.schedule[index].interval = selectedVal
                this.cameraData.ftp_monitor.schedule[index].start_time = '0:00'
                this.cameraData.ftp_monitor.schedule[index].end_time = '23:59'
              })
              console.log(this.cameraData.ftp_monitor.schedule)
              this.setDetailedFTPMotionSchedule()
            } else {
              this.cameraData.ftp_monitor.schedule = this.originalFTPMotionSchedule
            }
          })
      }
    },
    validateFTPMotionForm() {
      console.log('validateFTPMotionForm')
      // let tmp = {}
      // const newPermissionsArr = []
      console.log(this.cameraData)
      console.log(this.motionItems)
      console.log(this.motionItems)
      if (ability.can('update', 'cameras') || ability.can('update', 'camerasmanagement')) {
        this.cameraData.ftp_monitor.schedule = this.ftpMotionItems
        console.log('form validated')
        console.log(this.cameraData)
        const cameraDataLimited = {}
        cameraDataLimited.camera_id = this.cameraData.camera_id
        cameraDataLimited.ftp_monitor = JSON.parse(JSON.stringify(this.cameraData.ftp_monitor))
        cameraDataLimited.ftp_monitor.schedule = []
        this.cameraData.ftp_monitor.schedule.forEach(item => {
          const tmp = {}
          tmp.day_of_week = item.day_of_week
          tmp.start_time = item.start_time
          tmp.end_time = item.end_time
          cameraDataLimited.ftp_monitor.schedule.push(tmp)
        })
        cameraDataLimited.orig_cameraData = this.cameraData
        console.log(cameraDataLimited)

        store.dispatch('app-camera/updateCamera', cameraDataLimited)
          .then(response => {
            console.log(response)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Camera Updated',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            console.log(error)
            let theErrorStr = 'The following error(s) where received:\n'
            if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined' && typeof error.response.data.errors !== 'undefined') {
              error.response.data.errors.forEach(item => {
                theErrorStr += `${item.message}\n`
              })
            } else {
              theErrorStr += `${error}\n`
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error updating Camera information.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: theErrorStr,
              },
            })
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
