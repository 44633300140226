var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-media", { staticClass: "mb-2" }, [
        _c(
          "h4",
          { staticClass: "mb-1" },
          [
            _c("feather-icon", {
              staticClass: "mr-50",
              attrs: { icon: "CameraIcon", size: "22" }
            }),
            _vm._v(
              " Camera Permissions -- " +
                _vm._s(_vm.cameraData.sitename) +
                ", " +
                _vm._s(_vm.cameraData.location_name)
            ),
            _c("br")
          ],
          1
        ),
        _c("small", [_vm._v("Camera: " + _vm._s(_vm.cameraData.unit_number))]),
        _c("br")
      ]),
      _c("validation-observer", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.validateForm)
                      }
                    }
                  },
                  [
                    _c(
                      "b-row",
                      { staticClass: "mt-2" },
                      [_c("b-col", [_c("h4", [_vm._v("User Permissions")])])],
                      1
                    ),
                    _vm.usersPermissionsList.length > 0
                      ? _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c("b-table", {
                                  staticClass: "mb-0",
                                  attrs: {
                                    striped: "",
                                    responsive: "",
                                    items: _vm.usersPermissionsList
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "cell(fullName)",
                                        fn: function(data) {
                                          return [
                                            _vm._v(
                                              " " + _vm._s(data.value) + " "
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "cell(username)",
                                        fn: function(data) {
                                          return [
                                            _vm._v(
                                              " " + _vm._s(data.value) + " "
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "cell()",
                                        fn: function(data) {
                                          return [
                                            _c("b-form-select", {
                                              attrs: {
                                                id: data.item.username,
                                                options:
                                                  data.item.permissions
                                                    .theItems,
                                                clearable: false,
                                                "input-id": data.item.username
                                              },
                                              on: {
                                                input: _vm.updatePermissions
                                              },
                                              model: {
                                                value:
                                                  data.item.permissions.current
                                                    .value,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    data.item.permissions
                                                      .current,
                                                    "value",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "data.item.permissions.current.value"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.usersPermissionsList.length > 0
                      ? _c(
                          "b-row",
                          { staticClass: "mt-2" },
                          [
                            _c(
                              "b-col",
                              { attrs: { md: "4" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                    attrs: {
                                      variant: "primary",
                                      block: "",
                                      type: "submit"
                                    }
                                  },
                                  [_vm._v(" Save Changes ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _c("b-row", { staticClass: "mt-2 ml-1" }, [
                          _vm._v(
                            " No users found. Please add users to the account before managing camera permissions. "
                          )
                        ])
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }