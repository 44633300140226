var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-2" },
        [
          _c(
            "b-col",
            { attrs: { cols: "8" } },
            [
              _c(
                "h4",
                { staticClass: "mb-1" },
                [
                  _c("feather-icon", {
                    staticClass: "mr-50",
                    attrs: { icon: "DatabaseIcon", size: "22" }
                  }),
                  _vm._v(
                    " Logs -- " +
                      _vm._s(_vm.cameraData.sitename) +
                      ", " +
                      _vm._s(_vm.cameraData.location_name)
                  ),
                  _c("br")
                ],
                1
              ),
              _c("small", [
                _vm._v("Camera: " + _vm._s(_vm.cameraData.unit_number))
              ]),
              !_vm.cameraData.is_active
                ? _c(
                    "b-badge",
                    {
                      staticClass: "text-capitalize ml-1",
                      attrs: { pill: "", variant: "light-danger" }
                    },
                    [_vm._v(" Disabled ")]
                  )
                : _vm._e(),
              _c("br")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-2" },
        [
          _c(
            "b-col",
            [
              _c(
                "b-tabs",
                {
                  model: {
                    value: _vm.tabIndex,
                    callback: function($$v) {
                      _vm.tabIndex = $$v
                    },
                    expression: "tabIndex"
                  }
                },
                [
                  _c(
                    "b-tab",
                    {
                      attrs: { active: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c("feather-icon", {
                                staticClass: "mr-50",
                                attrs: { icon: "AlertOctagonIcon", size: "18" }
                              }),
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v("Alerts")
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("camera-edit-tab-alerts-logs", {
                            attrs: { "camera-data": _vm.cameraData }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "b-tab",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c("feather-icon", {
                                staticClass: "mr-50",
                                attrs: { icon: "FastForwardIcon", size: "18" }
                              }),
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v("Boot")
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("camera-edit-tab-boot-logs", {
                            attrs: { "camera-data": _vm.cameraData }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "b-tab",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c("feather-icon", {
                                staticClass: "mr-50",
                                attrs: { icon: "SendIcon", size: "18" }
                              }),
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v("Commands Sent")
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("camera-edit-tab-commands-sent-logs", {
                            attrs: { "camera-data": _vm.cameraData }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "b-tab",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c("feather-icon", {
                                staticClass: "mr-50",
                                attrs: { icon: "SendIcon", size: "18" }
                              }),
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v("Commands Qued")
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("camera-edit-tab-commands-qued-logs", {
                            attrs: { "camera-data": _vm.cameraData }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "b-tab",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c("feather-icon", {
                                staticClass: "mr-50",
                                attrs: { icon: "CameraIcon", size: "18" }
                              }),
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v("Camera Logs")
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("camera-edit-tab-camera-logs", {
                            attrs: {
                              "camera-data": _vm.cameraData,
                              "do-refresh": _vm.refreshCameraLog
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }